import React, { Component, useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  useField,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import { updatePartialCompleteData } from "../../redux/SelectedCampaign/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  faPenSquare,
  faCheck,
  faArrowRightToBracket,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Formik field: Creates a list of checkboxes that all point to the same array
 * field in formik. Like a multiselect type with checkboxes. 
 * @param {object} props
 * @param {string} props.name
 * @param {Array<{value: string | number, label: string}>} props.itemList
 * @param {Array<object>} props.values
 * @param {Array<string | number>} props.savedValue
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
function MultiselectCheckboxList({
  name,
  itemList,
  values,
  savedValue,
  disabled = false,
}) {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div>
          {itemList.map((item) => (
            <p>
              <label className="checkbox" disabled={disabled} key={item.value}>
                <input
                  name={name}
                  type="checkbox"
                  disabled={disabled}
                  checked={
                    disabled
                      ? savedValue && savedValue.indexOf(item.value) !== -1
                      : values[name] && values[name].includes(item.value)
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      arrayHelpers.push(item.value);
                    } else {
                      const idx = values[name].indexOf(item.value);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <span className="is-left">{item.label}</span>
                <span className="is-right">({item.value})</span>
              </label>
            </p>
          ))}
        </div>
      )}
    />
  );
}

const VALIDATION_SCHEMA = Yup.object().shape({
  partialScriptID: Yup.string(),
  partialAnswerList: Yup.array().of(Yup.string()),
});

/**
 *
 * @param {object} props
 * @param {string} props.partialScriptID Script id for partial completes
 * @param {Array<number>} props.partialAnswerList List of acceped answers for partial complete question
 * @param {Array} props.script survey Script for the campaign
 * @param {function} props.updatePartialCompleteData Save campaign data callback
 * @returns
 */
function ScriptTools({ partialScriptID, partialAnswerList, script, ...props }) {
  const [editing, setEditing] = useState(false);

  console.log(props);

  /**
   * Formik submit callback
   * @param {*} values
   * @param {*} param1
   * @returns
   */
  const onSubmit = (values, { setSubmitting }) => {
    // console.log("Submitting", values);
    setSubmitting(true);

    // The validation should take care of protecting us from these parses

    // Empty string id counts as null
    let psID = values.partialScriptID;
    if (psID == "") {
      psID = null;
    }

    // Empty list should be null in DB
    let alist = values.partialAnswerList;
    if (alist == []) {
      alist = null;
    }

    // No script id, no questions
    if (psID == null) {
      alist = null;
    }

    // Calls POST on campaign data under the hood
    // so the campaign data with reload after
    return props.updatePartialCompleteData(
      psID,
      alist
    );
  };

  const initValues = {
    partialScriptID,
    partialAnswerList,
  };

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        // handleBlur,
        // handleChange,
        // handleReset,
        handleSubmit,
        dirty,
        errors,
      }) => {
        // console.group("Formik render method");
        // console.log("values", values);
        // console.log("errors", errors);
        // console.log("dirty", dirty);
        // console.log("other", isSubmitting, handleSubmit);
        // console.groupEnd();

        return (
          <div className="tile is-parent is-vertical">
            <h4 className="title is-4 has-text-centered">SCRIPT OPTIONS</h4>
            <div className="tile is-parent">
              <div className="tile is-child pr-5">
                <ScriptIDSelector
                  partialScriptID={partialScriptID}
                  scriptIDList={script.map((q) => q.id)}
                  disabled={!editing}
                />
              </div>
              <div className="tile is-right">
                <div className="field">
                  Select Partial Complete Valid Answers
                  <AnswerSelector
                    partialScriptID={!editing ? partialScriptID : values.partialScriptID}
                    partialAnswerList={partialAnswerList}
                    script={script}
                    disabled={!editing}
                    values={values}
                  />
                  {values.partialAnswerList && values.partialAnswerList.length == 0 && (
                    <p>All answers will be counted as valid</p>
                  )}
                </div>
              </div>
              {/* Edit, cancel buttons */}
              {/* {!editing ? ( */}
              <button
                disabled={editing}
                className={`button normal with-icon ${
                  !editing && "has-text-primary"
                }`}
                onClick={() => setEditing(true)}
                title="Edit"
              >
                <span className="icon is-medium is-right">
                  <FontAwesomeIcon icon={faPenSquare} />
                </span>
              </button>
              {/* ) : ( */}
              <button
                disabled={!editing}
                className={`button normal with-icon ${
                  editing && "has-text-danger"
                }`}
                onClick={() => setEditing(false)}
                title="Cancel"
              >
                <span className="icon is-medium is-right">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
              </button>
              {/* )} */}
              <button
                disabled={!editing || isSubmitting}
                className={`button normal with-icon ${
                  editing && "has-text-success"
                }`}
                title="Save"
                type="submit"
                onClick={handleSubmit}
              >
                <span className="icon is-medium is-right">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

function ScriptIDSelector({ scriptIDList, partialScriptID, disabled }) {
  return (
    <div className="field ">
      Partial Complete Script ID
      {disabled ? (
        <label className="label">{partialScriptID || "--"}</label>
      ) : (
        <label className="label">
          <Field
            as="select"
            className="select is-fullwidth"
            name="partialScriptID"
            disabled={disabled}
          >
            <option value={""}>- Partials disabled -</option>
            {[...scriptIDList].map((id) => (
              <option value={id} key={`script-id-key-${id}`}>
                {id}
              </option>
            ))}
          </Field>

          <ErrorMessage
            name="partialScriptID"
            component="div"
            className="help is-danger"
          />
        </label>
      )}
    </div>
  );
}

/**
 *
 * @param {object} props
 * @param {string} props.partialScriptID Script id for partial completes
 * @param {Array<number>} props.partialAnswerList List of acceped answers for partial complete question
 * @param {Array<{answers: Array, id: string}>} props.script survey Script for the campaign
 * @param {object} props.values formik value list
 * @param {boolean} props.disabled Disables the form controls in edit mode
 * @returns
 */
function AnswerSelector({
  script,
  partialScriptID,
  partialAnswerList,
  values,
  disabled = true,
}) {
  const q = script.filter((q) => q.id === partialScriptID)[0];
  if (!partialScriptID || !q) {
    return <div>--</div>;
  }
  return (
    <MultiselectCheckboxList
      name="partialAnswerList"
      itemList={q.answers.map((a) => ({ value: a.value, label: a.text }))}
      values={values}
      savedValue={partialAnswerList}
      disabled={disabled}
    />
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePartialCompleteData: (partialScriptId, partialAnswerList) =>
        updatePartialCompleteData(partialScriptId, partialAnswerList),
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(ScriptTools);
